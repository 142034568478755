.input-field {
	width: 100%;
}

.form-container {
	width: 85%;
}

.form-button {
	height: 3rem;
	width: 70%;
}

.form-link {
	text-decoration: none;
}
