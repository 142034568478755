@import './alerts.scss';
@import './auth.scss';
body {
	font-family: 'Poppins', sans-serif;
}
.zoom-out-button {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border: none;
	border-radius: 2px;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
	color: #333;
	cursor: pointer;
	font-size: 18px;
	height: 36px;
	width: 36px;
	margin: 0;
	padding: 5px;
	text-align: center;
}

.zoom-out-button:hover {
	background-color: #eee;
}
